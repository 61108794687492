.icon {
    font-family: 'eeeban' !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'Rubrik';
    src: url('../assets/fonts/Rubrik.otf');
}
@font-face {
    font-family: 'RubrikBold';
    src: url('../assets/fonts/RubrikBold.otf');
}
@font-face {
    font-family: 'RubrikMedium';
    src: url('../assets/fonts/RubrikMedium.otf');
}
@font-face {
    font-family: 'RubrikSemiBold';
    src: url('../assets/fonts/RubrikSemiBold.otf');
}
