.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: 'rgba(255,255,255,0)'; //这里不能是黑色透明
    z-index: 1990;
    display: flex;
}
